// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 44px;
}

body {
  background: black;
  margin: 0;
  padding: 0;
  font-family: "Calibri", sans-serif;
  color: white;
}

.bg-primary {
  background-color: #00a7e7 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

.text-primary {
  color: #00a7e7 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,SAAA;EACA,UAAA;EACA,kCAAA;EACA,YAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AACA;EACE,oCAAA;AAEF;;AACA;EACE,yBAAA;AAEF","sourcesContent":["html {\n  font-size: 44px;\n}\n\nbody {\n  background: black;\n  margin: 0;\n  padding: 0;\n  font-family: 'Calibri', sans-serif;\n  color: white;\n}\n\n.bg-primary {\n  background-color: #00a7e7 !important;\n}\n.bg-light {\n  background-color: #ffffff !important;\n}\n\n.text-primary {\n  color: #00a7e7 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
