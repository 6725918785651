import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image'
import Logo from './Logo';
import './styles/list.scss';
import { useParams } from "react-router";

const getCompanyName = (company) => company.display_name || company.name;
const sort = (a, b) => {
    const name_a = getCompanyName(a).toLowerCase();
    const name_b = getCompanyName(b).toLowerCase();

    return name_a > name_b ? 1 : -1;
}

const fontSizeAdapter = (selector) => {
    const boxes = document.querySelectorAll(selector);
    boxes.forEach((box) => {
        const { clientHeight, scrollHeight } = box;
        if (!clientHeight || !scrollHeight) return;

        if (scrollHeight > clientHeight) {
            box.style.fontSize = parseInt(box.style.fontSize) - 4 + 'px';
            fontSizeAdapter(selector);
        }
    })
}

const List = () => {
    const { zone: zoneSlug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [zone, setZone] = useState({});

    useEffect(() => {
        const fetchZone = async () => {
            const url = process.env.REACT_APP_COCKPIT_URL + '/api/collections/get/zones?simple=1&fields[name]=1&filter[slug]=' + decodeURI(zoneSlug);
            const response = await fetch(url);
            if (!response.ok) return;
            const result = await response.json();
            return result[0];
        }
        const fetchCompanies = async () => {
            const zone = await fetchZone();
            if (!zone) return;
            setZone(zone);

            let url = process.env.REACT_APP_COCKPIT_URL + '/api/collections/get/companies?simple=1&populate=1&filter[rooms][$exists]&fields[rooms]=1&fields[display_name]=1&fields[name]=1&fields[logo]=1';
            let response = await fetch(url);
            if (!response.ok) {
                return
            }
            let result = await response.json();
            setCompanies(result);
        }

        setIsLoading(true);
        fetchCompanies().finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fontSizeAdapter('.text-placeholder');
    }, [companies]);

    if (isLoading || !companies.length || !zone._id) return <div/>;

    return <div className="wrapper">
        <div className="custom-container">
            <div className="sidebar">
                <div className="bg-primary text-center main-logo">
                    <Image src="/images/simplioffice-logo.png" srcSet="/images/simplioffice-logo.png 1x, /images/simplioffice-logo@2x.png 2x" alt="SimpliOffice Logo" width="57" height="57"/>
                </div>
                <h2 className="text-uppercase text-primary zone-name mt-1">{zone.name}</h2>
            </div>
            <div className="companies-list">
                {companies
                    .sort((a, b) => sort(a, b))
                    .map((company, index) => {
                        if (company.rooms.length === 0) return null;
                        let primaryRoom = company.rooms[0];
                        if (primaryRoom.zone._id.toLowerCase() !== zone._id) return null;
                        let hasLogo = company.logo && company.logo.length === undefined;

                        return (
                            <div key={index} className="company-card pt-1">
                                {hasLogo
                                    ? <div className="p-1 bg-light text-center w-100">
                                        <Logo url={company.logo.path}/>
                                    </div>
                                    : <div className="p-1 text-black text-center text-placeholder" style={{ fontSize: "50px" }}>
                                        {getCompanyName(company)}
                                    </div>
                                }
                                <div className="p-1 text-black text-uppercase mt-auto">
                                    <big>{primaryRoom.room_code}</big>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    </div>
}

export default List;