import React, {Component} from 'react';
import Image from 'react-bootstrap/Image'
import './styles/list.scss';

class Logo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            url: []
        }
    }

    async componentDidMount() {
        let baseURL = process.env.REACT_APP_COCKPIT_URL;
        let url = baseURL + '/api/imagestyles/style/CompanyLogo?src=' + encodeURIComponent(this.props.url) + '&token=' + encodeURIComponent(process.env.REACT_APP_COCKPIT_TOKEN)
        let response = await fetch(url);
        if (!response.ok) {
            return
        }

        let result = await response.text();
        this.setState({loading: false, url: baseURL + result})
    }

    render() {
        if (!this.state.loading) {
            return (
                <Image className="image" src={this.state.url} fluid/>
            );
        }

        return null;
    }
}

export default Logo;