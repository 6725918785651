import React, {Component} from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import List from './List'

class App extends Component {
    render() {
        return (
            <Router>
                <div className="app">
                    <Routes>
                        <Route path="/:zone" element={<List/>} />
                    </Routes>
                </div>
            </Router>
        );
    }
}

export default App;
