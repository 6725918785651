// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List .header {
  line-height: 1;
}

.custom-container {
  display: flex;
  justify-content: space-between;
}

.zone-name {
  writing-mode: vertical-lr;
  font-family: "Libre Franklin", sans-serif;
  margin: 0;
  white-space: nowrap;
  line-height: 1;
}

.companies-list {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 100vh;
}

.company-card {
  width: calc(25% - 12px);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 261px;
  margin-left: 12px;
}
.company-card:nth-child(n+5) {
  margin-top: 12px;
}

.image {
  width: 100%;
  height: 126px;
  object-fit: contain;
}

.text-placeholder {
  height: 148px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-logo {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.sidebar {
  width: 88px;
}`, "",{"version":3,"sources":["webpack://./src/styles/list.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;;AAIA;EACE,aAAA;EACA,8BAAA;AADF;;AAIA;EACE,yBAAA;EACA,yCAAA;EACA,SAAA;EACA,mBAAA;EACA,cAAA;AADF;;AAIA;EACE,SAAA;EACA,aAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;AADF;;AAIA;EACE,uBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EAMA,iBAAA;AANF;AAEE;EACE,gBAAA;AAAJ;;AAMA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,WAAA;AAHF","sourcesContent":[".List {\n  .header {\n    line-height: 1.0;\n  }\n}\n\n.custom-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.zone-name {\n  writing-mode: vertical-lr;\n  font-family: 'Libre Franklin', sans-serif;\n  margin: 0;\n  white-space: nowrap;\n  line-height: 1.0;\n}\n\n.companies-list {\n  flex: 1;\n  display: flex;\n  flex-wrap: wrap;\n  align-content: flex-start;\n  min-height: 100vh;\n}\n\n.company-card {\n  width: calc(25% - 12px);\n  background: white;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  height: 261px;\n\n  &:nth-child(n+5) {\n    margin-top: 12px;\n  }\n\n  margin-left: 12px;\n}\n\n.image {\n  width: 100%;\n  height: 126px;\n  object-fit: contain;\n}\n\n.text-placeholder {\n  height: 148px;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.main-logo {\n  display: flex;\n  height: 100px;\n  justify-content: center;\n  align-items: center;\n}\n\n.sidebar {\n  width: 88px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
